import { graphql } from "gatsby"
import { format, parseISO } from "date-fns"
import IDataPageProps from "../interfaces/data-page-props"
import SideLayout from "../layouts/side-layout"
import * as React from "react"
import PageTitle from "../components/page-title"
import MarkdownBody from "../components/markdown-body"
import Seo from "../layouts/seo"

export default function Page({ pageContext, data, location }: IDataPageProps) {
  const tab = pageContext.tab
  const post = data.post

  return (
    <SideLayout
      title={post.frontmatter.title}
      showTitle={false}
      tab={tab}
      location={location}
      crumbs={[]}
    >
      <div className="mt-8">
        <PageTitle
          title={post.frontmatter.title}
          supertitle={format(parseISO(post.fields.date), "LLLL d, yyyy")}
        />
        <MarkdownBody html={post.html} className="mt-8 text-justify" />
      </div>
      <></>
    </SideLayout>
  )
}

export function Head({ location, pageContext }: IDataPageProps) {
  return <Seo title={pageContext.title} />
}

export const pageQuery = graphql`
  query NewsItemBySlug($id: String!) {
    post: markdownRemark(id: { eq: $id }) {
      id
      excerpt(format: HTML)
      html
      fields {
        slug
        date
      }
      frontmatter {
        title
      }
    }
  }
`
